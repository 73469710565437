var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "flow-canvas",
      staticClass: "super-flow",
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.contextmenu.apply(null, arguments)
        },
      },
    },
    [
      _vm.temEdgeConf.visible
        ? _c("GraphLine", {
            attrs: {
              padding: _vm.linkPadding,
              graph: _vm.graph,
              link: _vm.temEdgeConf.link,
              "link-base-style": _vm.linkBaseStyle,
              "link-desc": _vm.linkDesc,
              "link-style": _vm.linkStyle,
            },
          })
        : _vm._e(),
      _vm._l(_vm.graph.linkList, function (edge, idx) {
        return _c("GraphLine", {
          key: edge.key,
          attrs: {
            index: idx,
            padding: _vm.linkPadding,
            graph: _vm.graph,
            link: edge,
            "link-base-style": _vm.linkBaseStyle,
            "link-desc": _vm.linkDesc,
            "link-style": _vm.linkStyle,
          },
        })
      }),
      _vm.moveNodeConf.isMove && _vm.hasMarkLine
        ? _c("MarkLine", {
            attrs: {
              width: _vm.clientWidth,
              height: _vm.clientHeight,
              "mark-color": _vm.markLineColor,
              "mark-line": _vm.moveNodeConf.markLine,
            },
          })
        : _vm._e(),
      _vm._l(_vm.graph.nodeList, function (node, idx) {
        return _c("GraphNode", {
          key: node.key,
          attrs: {
            index: idx,
            node: node,
            graph: _vm.graph,
            "is-move": node === _vm.moveNodeConf.node,
            "is-tem-edge": _vm.temEdgeConf.visible,
            "node-intercept": _vm.nodeIntercept(node),
            "line-drop": _vm.linkAddable,
            "node-drop": _vm.draggable,
          },
          on: {
            "node-mousedown": _vm.nodeMousedown,
            "node-mouseenter": _vm.nodeMouseenter,
            "node-mouseleave": _vm.nodeMouseleave,
            "node-mouseup": _vm.nodeMouseup,
            "side-mousedown": _vm.sideMousedown,
            "node-contextmenu": _vm.nodeContextmenu,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (ref) {
                  var meta = ref.meta
                  return [_vm._t("node", null, { meta: meta })]
                },
              },
            ],
            null,
            true
          ),
        })
      }),
      _c("GraphMenu", {
        attrs: {
          visible: _vm.menuConf.visible,
          graph: _vm.graph,
          position: _vm.menuConf.position,
          list: _vm.menuConf.list,
          source: _vm.menuConf.source,
        },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_vm.menuConf, "visible", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var item = ref.item
                return [_vm._t("menuItem", null, { item: item })]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.graph.graphSelected,
            expression: "graph.graphSelected",
          },
        ],
        ref: "selectAllMask",
        staticClass: "select-all__mask",
        style: _vm.maskStyle,
        attrs: { tabindex: "-1" },
        on: {
          blur: function ($event) {
            _vm.graph.graphSelected = false
          },
          mousedown: _vm.selectAllMaskMouseDown,
          contextmenu: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }