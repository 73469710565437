import { Component } from 'vue-property-decorator';
import { BaseComponent } from './base-component';
import { colorRgba } from '@common-src/utils/color';
import { SubSystemType } from '@common-src/model/enum';

const DEFAULT_COLORS = [
    '#43a4fd',
    '#ffc600',
    '#00fcff',
    '#fc8e58',
    '#25e368',
    '#e39025',
    '#fc5858',
    '#25c6e3',
    '#c592f1',
    '#7787ff'
];

export const ChartRingExtend = {
    series: {
        label: {
            show: true,
            normal: {
                formatter: '{b|{b}}\n{c|{c}} ({d|{d}%)}', // 图形外文字上下显示
                // padding: [0, -30], // 文字和图的边距
                rich: {
                    b: { // name 文字样式
                        fontSize: 12,
                        lineHeight: 20
                    },
                    c: { // value 文字样式
                        fontSize: 12,
                        lineHeight: 20
                    },
                    d: {
                        lineHeight: 25,
                        verticalAlign: 'center',
                        align: 'left'
                    }
                }
            }
        },
        labelLine: {
            length: 20,
            length2: 10
        }
    }
};

export const ChartLineExtend = {
    series: _.map(DEFAULT_COLORS, item => {
        return {
            areaStyle: {
                opacity: 0.8,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        { offset: 0, color: colorRgba(item, 0.4) },
                        { offset: 1, color: colorRgba(item, 0) }
                    ]
                }
            }
        };
    })
};

@Component
export class ReportChartBaseComponent extends BaseComponent {
    /**
     * 获取图表编码
     */
    get ChartCode() {
        return this.$route.path;
    }

    /**
     * 获取图表分类
     */
    get SubSystemName(): SubSystemType {
        return this.$route.meta.type as SubSystemType;
    }

    /**
     * 获取图表的主题
     * dark|light|jtlReportTheme
     */
    get ChartBaseTheme() {
        return 'jtlReportTheme';
    }
}

@Component
export class ReportChartRingComponent extends BaseComponent {
    get ChartRingExtend() {
        return ChartRingExtend;
    }

    get ChartRingSettings() {
        return {
            type: 'ring',
            radius: ['26%', '40%']
            // radius: ['36%', '50%']
        };
    }
}

@Component
export class ReportChartLineComponent extends BaseComponent {
    get ChartLineExtend() {
        return ChartLineExtend;
    }
}

@Component
export class ReportChartHistogramComponent extends BaseComponent {
    get HistogramXAxis() {
        return {
            type: 'value',
            // max: _.get(this.last7DayTop5AlarmData, 'response.totalCount'),
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: false
            }
        };
    }
    get HistogramExtend() {
        return {
            series: {
                showBackground: true,
                label: {
                    show: true,
                    position: 'right'
                }
            }
        };
    }
}
