var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "super-flow__node",
      class: { "can-move": _vm.nodeDrop },
      style: _vm.style,
      attrs: { tabindex: "-1" },
      on: {
        mousedown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "left", 37, $event.key, [
              "Left",
              "ArrowLeft",
            ])
          ) {
            return null
          }
          if ("button" in $event && $event.button !== 0) {
            return null
          }
          return _vm.nodeMousedown.apply(null, arguments)
        },
        mouseenter: _vm.nodeMouseenter,
        mouseleave: _vm.nodeMouseleave,
        mouseup: _vm.nodeMouseup,
        contextmenu: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.nodeContextmenu.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default", null, { meta: _vm.node.meta }),
      _vm._l(_vm.direction, function (dir, key) {
        return _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.output && _vm.lineDrop,
              expression: "output && lineDrop",
            },
          ],
          key: key,
          class: "node-side node-side-" + key,
          on: {
            contextmenu: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            },
            mousedown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft",
                ])
              ) {
                return null
              }
              if ("button" in $event && $event.button !== 0) {
                return null
              }
              $event.preventDefault()
              $event.stopPropagation()
              return function (evt) {
                return _vm.sideMousedown(evt, dir)
              }.apply(null, arguments)
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }