var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "super-flow__menu-container" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "flow__menu-mask",
      on: {
        mousemove: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
        mousedown: _vm.close,
      },
    }),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "super-flow__menu",
        style: _vm.style,
        attrs: { tabindex: "-1" },
        on: {
          mousemove: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
          },
          contextmenu: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
          },
          blur: _vm.close,
        },
      },
      [
        _vm._l(_vm.list, function (subList, index1) {
          return [
            _vm._l(subList, function (subItem, index2) {
              return _c(
                "li",
                {
                  key: "" + subItem.label + index2,
                  staticClass: "super-flow__menu-item",
                  class: { "is-disabled": subItem.disable },
                  on: {
                    click: function ($event) {
                      return _vm.select(subItem)
                    },
                  },
                },
                [
                  _vm._t(
                    "default",
                    function () {
                      return [
                        _c("span", {
                          staticClass: "super-flow__menu-item-icon",
                        }),
                        _c(
                          "span",
                          { staticClass: "super-flow__menu-item-content" },
                          [_vm._v(" " + _vm._s(subItem.label) + " ")]
                        ),
                      ]
                    },
                    { item: subItem }
                  ),
                ],
                2
              )
            }),
            _c("li", { key: index1, staticClass: "super-flow__menu-line" }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }